const colors = {
    light: {
        logoStart: "#14dca0",
        logoEnd: "#262E31",
        logoFace: "#14dca0",
        logoGoggle: "#262E31",
        logoMouth: "#ffffff",
        logoShine: "#565c5e",
        logoShadow: "drop-shadow( 0px 0px 20px rgba(0, 0, 0, .2))",
        accent: "#14dca0",
        primary: "#14dca0",
        secondary: "#021f2b",
        glass: "#071a29d2",
        text: "#000000",
        text2: "#454545",
        contrast: "#ffffff",
        comp1: "#ff6347",
        bgPrimary: "#fafafa",
        bgSecondary: "#ffffff",
        carouselText: "#ffffff",
        loadingBg: "#162B45",
        loadingShine: "#2c5b94",
        carouselBg: "#001025e6",
        transistion: "0.3s ease-out",
        textGradiant: "linear-gradient(to right, #14dca0 0%, #008cff 100%)",
        nShadow: "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
        tShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
    },
    dark: {
        logoStart: "#14dca0",
        logoEnd: "#ffffff",
        logoFace: "#14dca0",
        logoGoggle: "#ffffff",
        logoMouth: "#ffffff",
        logoShine: "#c5c5c5",
        logoShadow: "drop-shadow( 0px 0px 0px rgba(0, 0, 0, .1))",
        accent: "#14dca0",
        lightAccent: "rgba(20, 220, 160, 0.3)",
        primary: "#14dca0",
        secondary: "#79fcd5",
        glass: "#061624cc",
        text: "#ffffff",
        text2: "#c2c2c2",
        contrast: "#000000",
        comp1: "#ff6347",
        bgPrimary: "#031016",
        bgSecondary: "#0b2536",
        carouselText: "#ffffff",
        loadingBg: "#162B45",
        loadingShine: "#2c5b94",
        carouselBg: "#031016",
        transistion: "0.3s ease-out",
        textGradiant: "linear-gradient(to right, #14dca0 0%, #008cff 100%)",
        nShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
        tShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
    }
};

export default colors;